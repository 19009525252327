<template>
  <div id="overview-course">
    <div class="div-companies bg-white content-wrapper">
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center flex-wrap">
              <h4 style="color: #0007" class="mt-3">
                <i class="fas fa-graduation-cap" aria-hidden="true"></i>
                Academy - Comentários
              </h4>
              <div>
                <button class="btn btn-sm btn-outline-secondary" @click.prevent="$router.go(-1)">
                  <i class="fa fa-arrow-left mx-1"></i>Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="row m-0 p-0">
        <!-- novos comentarios -->
        <div class="col-12 col-sm-6 mb-4 mb-sm-0">
          <div class="d-flex align-items-center ml-2 mb-2">
            <i class="far fa-comment-dots mr-1"></i>
            <label class="m-0 p-0">Novos Comentários</label>
          </div>
          <p v-if="newsComments.length===0" class="text-muted w-100 text-center">Não há novos comentários.</p>
          <div class="d-flex align-items-center mb-2 mr-4 p-sm-2 custom-question"
               v-for="(comment, index) in newsComments" :key="index">
            <div class="col-11">
              <p class="m-0 p-0 text-muted" style="font-size: .7rem">{{ `Em ${convertDate(comment.createdAt)}` }}</p>
              <label class="m-0 p-0 font-weight-normal" style="font-size: 1.5rem;">{{ comment.question }}</label>
              <p class="m-0 p-0 small text-muted">{{
                  `Por ${comment.createdBy.name}, ${comment.createdBy.company}`
                }}</p>
              <hr class="m-0 p-0 mt-1 w-100">
            </div>
            <div class="col-1 d-flex">
              <i @click="$router.push(`/admin/academy/comments/answer/${comment._id}`)"
                 class="far fa-comment text-primary mr-2" style="cursor:pointer;" title="Responder comentário"></i>
              <!-- <i @click="deleteComment(comment._id)" class="fas fa-ban text-danger" style="cursor:pointer;"
                 title="Excluir comentário"></i> -->
            </div>
          </div>
        </div>
        <!-- comentarios respondidos -->
        <div class="col-12 col-sm-6 mt-4 mt-sm-0 border-left">
          <div class="d-flex align-items-center ml-2 mb-2">
            <i class="far fa-comments mr-1"></i>
            <label class="m-0 p-0">Comentários respondidos</label>
          </div>
          <p v-if="answeredComments.length===0" class="text-muted w-100 text-center">Não há comentários respondidos.</p>
          <div class="d-flex align-items-center mb-2 mr-4 p-sm-2 custom-question"
               v-for="(comment, index) in answeredComments" :key="index">
            <div class="col-10">
              <label class="m-0 p-0">{{ comment.question }}</label>
              <p class="m-0 p-0 small text-muted">{{
                  `Por ${comment.createdBy.name} em ${convertDate(comment.createdAt)} - ${comment.createdBy.company}`
                }}</p>
              <p class="m-0 p-0 mt-2"><strong>R:</strong> {{ comment.answer }}</p>
              <p class="m-0 p-0 small text-muted" style="font-size: .6rem">{{
                  `Respondido por ${comment.answeredBy}`
                }}</p>
              <hr class="m-0 p-0 mt-1 w-100">
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <div class="custom-control custom-switch m-0 p-0" title="Mostrar comentário no curso">
                <input @change="toggleActive(comment._id, $event)" type="checkbox" class="custom-control-input w-25"
                       :id="comment._id" :checked="comment.showComment">
                <label class="custom-control-label m-0 p-0" :for="comment._id"></label>
              </div>
              <i @click="$router.push(`/admin/academy/comments/edit/${comment._id}`)"
                 class="far fa-edit text-primary mr-2" style="cursor:pointer;" title="Editar comentário"></i>
              <!-- <i @click="deleteComment(comment._id)" class="far fa-trash-alt text-danger" style="cursor:pointer;"
                 title="Excluir comentário"></i> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      comments: []
    }
  },
  computed: {
    newsComments: function () {
      var news = []
      this.comments.forEach(comment => {
        if (!comment.answered) news.push(comment)
      })
      return news
    },
    answeredComments: function () {
      var comments = []
      this.comments.forEach(comment => {
        if (comment.answered) comments.push(comment)
      })
      return comments
    }
  },
  methods: {
    /**
     * Metodo que busca comentarios na inicialização
     */
    getComments: async function () {
      await this.$tallos_academy_api.get(`courses/comments/${this.$route.params.id}`)
          .then(response => {
            this.comments = response.data
          })
          .catch(() => {

            this.$toasted.global.defaultError({msg: "Não foi possivel encontrar comentários."})
          })
    },
    /**
     * Esse metodo pega uma data string (2021-02-25T16:26:02.458Z) converte para timestamp e formata usando intl
     * @param {String} date data a ser formatada
     */
    convertDate: function (date) {
      var dateParse = Date.parse(date);
      var currentDate = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/Fortaleza'
      }).format(dateParse)
      return currentDate
    },

    /**
     * Metodo para deletar uma categoria
     * @param {String} id id do comentario a ser excluido
     */
    // deleteComment: async function (id) {
    //   var result = prompt(`Deseja excluir o comentário?\nDigite 'sim' para confirmar e 'não' para cancelar.`)
    //   if (result.toLowerCase() === "sim" || result.toLowerCase() === "s") {
    //     await this.$tallos_academy_api.delete(`comments/admin/${id}`, {data: {deletedBy: this.$store.getters.admin.name}})
    //         .then(response => {
    //           this.$toasted.global.defaultSuccess({msg: response.data.message})
    //           this.getComments()
    //         })
    //         .catch(() => {
    //           this.$toasted.global.defaultError({msg: 'Erro ao excluir o comentário.'})
    //         })
    //   }
    // },

    toggleActive: async function (id, event) {
      const statusToChange = event.target.checked
      const answeredBy = this.$store.getters.admin.name;
      await this.$tallos_academy_api.put(`comments/admin/${id}`, {
        answeredBy: answeredBy,
        showComment: statusToChange
      }).then(() => {
        this.$toasted.global.defaultSuccess({msg: statusToChange ? 'O comentário está ativado' : 'O comentário está desativado'})
      })
          .catch(error => {
            this.$toasted.global.defaultError({msg: error.response.data.message})

          })
    }

  },
  mounted: function () {
    this.getComments()
  }
}
</script>

<style scoped>
* {
  transition: .4s;
}

.custom-question:hover {
  background-color: #eee;
}
</style>